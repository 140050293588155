.signature-canvas-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.signature-canvas {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border: black 1px solid;
    display: block; /* Remove extra space below the canvas */
}

@font-face {
    font-family: 'FreehandRegular';
    src: url('../../../public/Freehand-Regular.ttf') format('tff');
    font-weight: normal;
    font-style: normal;
}