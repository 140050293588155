.estimate-control-overlay {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    pointer-events: none;
}

.full-page-without-toolbar {
    width: 100%;
    height: calc(100% - 64px);
    margin: 0;
}

.bottom-controls-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.bottom-central-controls {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.bottom-central-controls-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bottom-central-controls-button {
    margin-top: 10px;
    margin-bottom: 10px;
}

.bottom-right-element-control-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: relative;
}

.center-on-user-fab-container {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    padding-bottom: 50px;
}

.add-element-speed-dial-container {
    padding-right: 50px;
    padding-bottom: 50px;
}

.overlaid-control {
    pointer-events: auto;
}

.test-image {
    height: 40vmin;
    pointer-events: none;
}

/*
 * Always set the map height explicitly to define the size of the div element
 * that contains the map.
 */
#map {
    height: 100%;
}

/*
 * Optional: Makes the sample page fill the window.
 */
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}