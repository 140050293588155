.control-overlay {
    top: 0px;
    left: 0px;
    position: absolute;
    z-index: 10;
    pointer-events: none;
}

.controls-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.bottom-central-controls {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.bottom-central-controls-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bottom-central-controls-button {
    margin-top: 10px;
    margin-bottom: 10px;
}

.bottom-right-element-control-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.add-lead-fab-container {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    padding-bottom: 50px;
}