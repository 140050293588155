/* For Webkit browsers */
*::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
    background: #121212; /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
    background-color: #888; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #121212; /* creates padding around scroll thumb */
}

/* For Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #888 #121212;
}
