.drawable-image-dialog {
}

.canvas-container {
    height: 100%;
    width: 100%;
}

.canvas {
    width: 100%;
    max-height: 80vh;
    display: block; /* Remove extra space below the canvas */
}

.undo-redo-overlay {
    pointer-events: all; /* We don't allow pointer events to pass through here because if someone spams the undo/redo buttons, */
                         /* once it gets to the end of the stack the button disables and the pointer events will pass through to the canvas and draw a tiny dot on it. */
                         /* This in and of itself isn't a problem, but it will reset the undo/redo stack, which can confuse the user */
}

.text-field {
    position: absolute;
    z-index: 1;
    background: transparent;
}

.text-field .MuiOutlinedInput-root fieldset {
    border: none;
}

.text-field input {
    color: #000; /* Adjust color based on theme if needed */
    font-size: 16px;
    font-family: Roboto, sans-serif; /* Ensure this matches MUI */
}

.drawing-menu-overlay {
    position: absolute;
    bottom: 72px; /*Need to account for the dialog actions footer*/
    left: 24px;
    pointer-events: none;
}

.drawing-palette-overlay {
    bottom: 72px; /*Need to account for the dialog actions footer*/
    left: 24px;
    pointer-events: none;
}

.loader-overlay {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top : 0px;
    left: 0px;
}

.overlaid-control {
    pointer-events: auto;
}
